/* Customize tab */

p-tabPanel {
    >.ui-tabview-panel {
        padding: 15px;
    }
    &.no-padding {
        >.ui-tabview-panel {
            padding: 0;
        }
    }
}

.vs-form-container {
    .__modal-tab-title {
        text-transform: uppercase;
        color: $vs-edit-color;
        margin: 5px 0 15px;
    }
}