p-tabView.vs-tab-view.style-1 {
    .ui-tabview.ui-tabview-top {
        .ui-tabview-nav li {
            outline: none;
        }
        .ui-tabview-nav li a {
            color: #666;
            padding: 5px 10px;
        }
        .ui-tabview-nav li.ui-state-active a {
            font-weight: 700;
            color: $vs-text-color;
        }
    }
    .ui-tabview-panel {
        padding: 0;
    }
}

p-tabView.vs-tab-view-info-account {
    .ui-tabview.ui-tabview-top {
        .ui-tabview-nav {
            display: flex;
            flex-wrap: wrap;
            li {
                background-color: $vs-base-color;
                border: none;
                margin-right: 0;
                border-radius: 0;
                flex: 1 0 110px;
                max-width: 200px;
                @media screen and (max-width: 640px) {
                    width: 100%;
                }
                a {
                    color: #fff;
                    font-weight: bold;
                }
                &:focus {
                    outline: none;
                }
                &.ui-state-active {
                    background-color: #fff;
                    a {
                        color: #0a89bf;
                    }
                }
                &:hover {
                    a {
                        color: #666666;
                    }
                }
            }
        }
        .vs-btn {
            &.__btn-accordion {
                padding: 0px 10px;
                margin-right: 5px;
                border-radius: 0;
            }
            &.activated {
                background-color: $vs-validate-color;
            }
        }
    }
    .ui-tabview-panel {
        padding: 0;
        .__content-body {
            border: 1px solid #ddd;
        }
        .__top-tabview-action {
            background-color: #fff;
            padding-top: 10px;
            padding-bottom: 5px;
        }
    }
}